// Angular
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
// Loading bar
import { LoadingBarService } from '@ngx-loading-bar/core';
// RxJS
import { Observable } from 'rxjs';
// Portlet
import { PortletBodyComponent } from './portlet-body.component';
import { PortletHeaderComponent } from './portlet-header.component';
import { PortletFooterComponent } from './portlet-footer.component';
// Layout
import { LayoutConfigService } from '@core/_base/layout';

export interface PortletOptions {
	test?: any;
}

export interface PortletProgressOptions {
	progress: boolean;
	overlay?: boolean;
	overlayBackdrop?: string;
	// text?: string
}

@Component({
	selector: 'kt-portlet',
	templateUrl: './portlet.component.html',
	exportAs: 'ktPortlet',
	styleUrls: ['./portlet.component.scss'],
	host: {
		class: 'kt-portlet',
	},
	encapsulation: ViewEncapsulation.None,
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortletComponent implements OnInit, AfterViewInit {
	// Public properties
	@Input() loading$!: Observable<boolean>;
	// portlet extra options
	@Input() options!: PortletOptions;
	// portlet root classes
	@Input() customClass!: string;

	// @Input() progress = false;
	// @Input() progressOverlay = true;

	@Input() portletProgressOptions!: PortletProgressOptions;

	// @ViewChild('portlet', { static: true }) portlet!: ElementRef;

	// portlet header component template
	@ViewChild(PortletHeaderComponent, { static: true }) header!: PortletHeaderComponent;
	// portlet body component template
	@ViewChild(PortletBodyComponent, { static: true }) body!: PortletBodyComponent;
	// portlet footer component template
	@ViewChild(PortletFooterComponent, { static: true }) footer!: PortletFooterComponent;

	/**
	 * Component constructor
	 *
	 * @param el: ElementRef
	 * @param loader: LoadingBarService
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private readonly el: ElementRef, public loader: LoadingBarService, private readonly layoutConfigService: LayoutConfigService) {
		this.loader.complete();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (this.portletProgressOptions) {
			this.portletProgressOptions.progress = this.portletProgressOptions.progress ? this.portletProgressOptions.progress : false;
			this.portletProgressOptions.overlay = this.portletProgressOptions.overlay ? this.portletProgressOptions.overlay : false;
			this.portletProgressOptions.overlayBackdrop = this.portletProgressOptions.overlayBackdrop ? this.portletProgressOptions.overlayBackdrop : '';

			if (this.portletProgressOptions.overlayBackdrop !== '') {
				this.portletProgressOptions.overlay = false;
			}
		}
	}

	/**
	 * After view init
	 */
	ngAfterViewInit() {}
}
